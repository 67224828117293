import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//remix icons
import { RiGatsbyLine } from "react-icons/ri";

//bootstrap icons
import { BsBootstrap } from "react-icons/bs";

//simple icons
import { SiGraphql } from "react-icons/si"

//dev icons
import { DiStylus } from "react-icons/di"





import React from 'react';

const Icon = ({ icon, prefix = 'fas', lib}) => {
  switch(lib) {
    //font awesome
    case "fa": 
      return <FontAwesomeIcon icon={[prefix, icon]} size="lg" />;
    //remix icons
    case "ri":
      switch(icon) {
        case "Gatsby":
          return <RiGatsbyLine />;

        case "Bootstrap":
          return <BsBootstrap />;
      }
      break;
    //simple icons
    case "si":
      switch(icon) {
        case "GraphQL":
          return <SiGraphql />;
      }
      break;
    //dev icons
    case "di":
      switch(icon) {
        case "Stylus":
          return <DiStylus />;
      }
      break;
    //default icon  
    default:
      return <FontAwesomeIcon icon={[prefix, icon]} size="lg" />

  }

}


export default Icon;
