export const indexMenuLinks = [
  {
    name: 'About',
    link: '/#about',
    icon: 'user-tie',
  },
  {
    name: 'Projects',
    link: '/#projects',
    icon: 'terminal',
  },
  {
    name: 'Contact',
    link: '/#contact',
    icon: 'envelope',
  },
  {
    name: 'Business',
    link: '/business',
    icon: 'building',
  },
];

export const blogMenuLinks = [
  {
    name: 'Home',
    link: '/',
    icon: 'code',
  },
];
