export const socialIconList = [
  {
    name: 'github',
    prefix: 'fab',
    link: 'https://github.com/xiyzzc',
  },
  {
    name: 'codepen',
    prefix: 'fab',
    link: 'https://codepen.io/xiyzzc',
  },
  {
    name: 'linkedin',
    prefix: 'fab',
    link: 'https://www.linkedin.com/in/isaac-day/'
  }
  // Add further social links with the icon of choice and link here
  // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];
